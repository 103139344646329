<template>
  <div class="settings-profile">
    <alert
      v-if="!user.isEmailConfirmed"
      :title="$t('confirmEmail.title')"
      :description="$t('confirmEmail.instructions')"
    >
      <el-button slot="button" type="plain" :disabled="sendAgainDisabled" @click="handleSendAgain">
        {{ $t('confirmEmail.sendAgain') }}
      </el-button>
    </alert>
    <el-card class="settings-profile__card" shadow="none">
      <h4 class="settings-profile__content__heading">{{ $t('profileSettings') }}</h4>
      <el-row type="flex" class="settings-profile__content" justify="space-between">
        <el-col :span="11">
          <el-form
            ref="form"
            :model="formData"
            :rules="formRules"
            label-position="top"
            label-width="100px"
            :show-message="true"
            class="settings-profile__form"
            size="small"
          >
            <el-form-item>
              <div class="settings-profile__form__avatar">
                <el-avatar
                  v-if="avatarPreview || user.avatar"
                  :src="avatarPreview || user.avatar"
                />
                <el-avatar v-else>{{ user.name.slice(0, 1) }}</el-avatar>

                <input
                  id="file"
                  ref="avatar"
                  type="file"
                  name="file"
                  class="settings-profile__form__avatar__upload"
                  @change="addAvatarPreview"
                />
                <label for="file" class="settings-profile__form__avatar__label"></label>
                <el-button>
                  <icon width="16" height="16" name="download"></icon>
                  {{ avatarPreview ? $t('actions.save') : $t('actions.upload') }}
                </el-button>
                <el-button @click="removeAvatar">
                  <icon width="16" height="16" name="trash"></icon>
                  {{ $t('actions.remove') }}
                </el-button>
              </div>
            </el-form-item>
            <el-form-item
              v-for="item in formItems"
              :key="item.name"
              :label="$t(`user.${item.name}.label`)"
              :prop="item.name"
            >
              <el-input v-model="formData[item.name]" :name="item.name">
                <icon slot="prefix" width="16" height="16" :name="item.icon" />
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('language')">
              <el-select v-model="$root.$i18n.locale" @change="handleLocaleChange">
                <icon slot="prefix" width="16" height="16" name="translate" />
                <el-option key="english" value="en" label="English" />
                <el-option key="russian" value="ru" label="Русский" />
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="11">
          <el-form
            label-position="top"
            label-width="100px"
            :show-message="true"
            class="profile_form"
            size="small"
          >
            <el-form-item :label="$t('user.email.label')">
              <el-input :value="user.email" disabled>
                <icon slot="prefix" width="16" height="16" name="mail" />
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('user.password.label')">
              <el-input value="********" disabled>
                <icon slot="prefix" width="16" height="16" name="lock" />
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row v-show="formDataChanged" class="settings-profile__footer">
        <el-col class="settings-profile__footer__inner">
          <el-button class="el-button--min-width" @click="resetFormData">
            {{ $t('actions.cancel') }}
          </el-button>
          <el-button
            type="primary"
            class="el-button--min-width"
            :loading="submitLoading"
            :disabled="submitDisabled"
            @click="handleEditProfile"
          >
            {{ $t('actions.save') }}
          </el-button>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import Alert from '@/components/Alert'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SettingsProfile',

  components: {
    Alert,
  },

  data() {
    return {
      formItems: [
        { name: 'name', icon: 'user' },
        { name: 'phone', icon: 'phone-ring' },
        { name: 'company', icon: 'business' },
      ],
      formData: {
        name: '',
        company: '',
        phone: '',
      },
      formRules: {
        name: {
          required: true,
          message: this.$i18n.t('user.name.required'),
          trigger: 'blur',
        },
        phone: {
          required: true,
          message: this.$i18n.t('user.phone.required'),
          trigger: 'blur',
        },
        company: [
          {
            required: true,
            message: this.$i18n.t('user.company.required'),
            trigger: 'blur',
          },
          {
            min: 3,
            max: 100,
            message: this.$i18n.t('user.company.invalidLength'),
            trigger: 'blur',
          },
        ],
      },
      avatarPreview: null,

      submitLoading: false,
      submitDisabled: false,
      sendAgainDisabled: false,
    }
  },

  computed: {
    ...mapState('user', ['user']),
    formDataChanged() {
      return (
        this.formData.name !== this.user.name ||
        this.formData.company !== this.user.company ||
        this.formData.phone !== this.user.phone
      )
    },
  },

  created() {
    this.resetFormData()
  },

  methods: {
    ...mapActions('user', ['editUser', 'sendEmailConfirmation']),
    resetFormData() {
      this.$refs.form && this.$refs.form.clearValidate()
      this.formData.name = this.user.name
      this.formData.company = this.user.company
      this.formData.phone = this.user.phone
    },
    addAvatarPreview() {
      const files = this.$refs.avatar.files
      if (files.length === 1) {
        this.avatarPreview = window.URL.createObjectURL(files[0])
      }
    },
    removeAvatar() {
      if (this.avatarPreview) {
        this.avatarPreview = null
      } else if (this.user.avatar) {
        // clear user avatar
      }
    },
    handleLocaleChange(locale) {
      localStorage.setItem('locale', locale)
    },
    async handleSendAgain() {
      const { error } = await this.sendEmailConfirmation(this.formData)

      if (error) {
        this.sendAgainDisabled = true
        return false
      } else {
        this.sendAgainDisabled = true
        this.$notify({
          type: 'success',
          title: this.$i18n.t('messages.success'),
          message: this.$i18n.t('emailSent'),
        })
      }
    },
    async handleEditProfile() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.submitLoading = true
          const { error } = await this.editUser(this.formData)
          this.submitLoading = false

          if (error) {
            this.submitDisabled = true
            return false
          } else {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('messages.success'),
              message: this.$i18n.t('profileUpdated'),
            })
          }
        } else {
          this.submitDisabled = true
          return false
        }
      })
    },
  },
}
</script>

<i18n>
{
  "en": {
    "profileSettings": "Profile settings",
    "confirmEmail": {
      "title": "Confirm your email address",
      "instructions": "Please check your inbox for a confirmation email. Follow the link in the email to confirm your email address.",
      "sendAgain": "Send again"
    },
    "account": "Account",
    "language": "Language",
    "profileUpdated": "Profile has been updated successfully",
    "emailSent": "Confirmation email has been sent to your email address"
  },
  "ru": {
    "profileSettings": "Настройки профиля",
    "confirmEmail": {
      "title": "Подтвердите адрес электронной почты",
      "instructions": "Пожалуйста, проверьте свою электронную почту. Перейдите по ссылке в письме для подтверждении адреса электронной почты.",
      "sendAgain": "Отправить"
    },
    "account": "Аккаунт",
    "language": "Язык",
    "profileUpdated": "Профиль успешно обновлен",
    "emailSent": "Письмо со ссылкой для подтверждения адреса электронной почты отправлено на вашу электронную почту"
  }
}
</i18n>

<style scoped lang="scss">
.settings-profile {
  max-width: 640px;
  padding-top: $spacing-l;
  margin: 0 auto;
}
.settings-profile__card {
  padding: $spacing-s;
}
.settings-profile__content {
  height: calc(100% - 80px);
}
.settings-profile__content__heading {
  margin-bottom: $spacing-s;
}
.settings-profile__form .el-select {
  width: 100%;
}
.settings-profile__form__avatar {
  display: flex;
  align-items: center;
  * {
    margin-left: 16px;
  }
  *:first-child {
    margin-left: 0;
  }
}
.settings-profile__form__avatar__upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.settings-profile__form__avatar__label {
  position: absolute;
  left: 40px;
  width: 70px;
  height: 32px;
  display: block;
  cursor: pointer;
}
.settings-profile__footer {
  padding-top: $spacing-l;
}
.settings-profile__footer__inner {
  display: flex;
  justify-content: flex-end;
}
</style>
