<template>
  <div class="settings-cloud">
    <el-card class="settings-cloud__card" shadow="none">
      <h4 class="settings-cloud__content__heading">{{ $t('cloudSettings') }}</h4>
      <el-row type="flex" class="settings-cloud__content" justify="space-between">
        <el-col>
          <el-form
            ref="form"
            :model="formData"
            :rules="formRules"
            label-position="top"
            label-width="100px"
            :show-message="true"
            size="small"
          >
            <el-form-item :label="$t('name.label')" prop="name">
              <el-input v-model="formData.name" />
            </el-form-item>
            <el-form-item :label="$t('description')">
              <el-input v-model="formData.description" type="description" />
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row v-show="formDataChanged" class="settings-cloud__footer">
        <el-col class="settings-cloud__footer__inner">
          <el-button class="el-button--min-width" @click="resetFormData">
            {{ $t('actions.cancel') }}
          </el-button>
          <el-button
            type="primary"
            class="el-button--min-width"
            :loading="submitLoading"
            :disabled="submitDisabled"
            @click="handleEditCloud"
          >
            {{ $t('actions.save') }}
          </el-button>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SettingsCloud',

  data() {
    return {
      formData: {
        name: '',
        description: '',
      },
      formRules: {
        name: {
          required: true,
          message: this.$i18n.t('name.required'),
          trigger: 'blur',
        },
      },

      submitLoading: false,
      submitDisabled: false,
    }
  },

  computed: {
    ...mapGetters('data', ['activeCloud']),
    formDataChanged() {
      return (
        this.formData.name !== this.activeCloud.name ||
        this.formData.description !== this.activeCloud.description
      )
    },
  },

  created() {
    this.resetFormData()
  },

  methods: {
    ...mapActions('data', ['editCloud']),
    resetFormData() {
      this.$refs.form && this.$refs.form.clearValidate()
      this.formData.name = this.activeCloud.name
      this.formData.description = this.activeCloud.description
    },
    async handleEditCloud() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.submitLoading = true
          const { error } = await this.editCloud({ id: this.activeCloud.id, ...this.formData })
          this.submitLoading = false

          if (error) {
            this.submitDisabled = true
            return false
          } else {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('messages.success'),
              message: this.$i18n.t('cloudUpdated'),
            })
          }
        } else {
          this.submitDisabled = true
          return false
        }
      })
    },
  },
}
</script>

<i18n>
{
  "en": {
    "cloudSettings": "Cloud settings",
    "name": {
      "label": "Name",
      "required": "Please enter card name"
    },
    "cloudUpdated": "Cloud has been updated successfully"
  },
  "ru": {
    "cloudSettings": "Настройки облака",
    "name": {
      "label": "Название",
      "required": "Пожалуйста, введите название облака"
    },
    "cloudUpdated": "Облако успешно обновлено"
  }
}
</i18n>

<style scoped lang="scss">
.settings-cloud {
  max-width: 640px;
  padding-top: $spacing-l;
  margin: 0 auto;
}
.settings-cloud__card {
  padding: $spacing-s;
}
.settings-cloud__content {
  height: calc(100% - 80px);
}
.settings-cloud__content__heading {
  margin-bottom: $spacing-s;
}
.settings-cloud__footer {
  padding-top: $spacing-l;
}
.settings-cloud__footer__inner {
  display: flex;
  justify-content: flex-end;
}
</style>
