<template>
  <div class="settings-wallet">
    <div class="settings-wallet__panel">
      <el-card class="settings-wallet__card" shadow="none">
        <span>Account balance</span>
        <h6>-90, 00 ₽</h6>
      </el-card>
      <el-card class="settings-wallet__card" shadow="none">
        <span>Payment info</span>
        <el-button class="el-button--stretch" size="small">Edit info</el-button>
      </el-card>
      <el-card class="settings-wallet__card" shadow="none">
        <span>Contract</span>
        <el-button class="el-button--stretch" size="small">Download</el-button>
      </el-card>
    </div>
    <data-table
      ref="table"
      :title="$t('sources')"
      :data="operations"
      :data-columns="tableColumns"
      :loading="loading"
      :empty-text="$t('noOperations')"
      :not-found-text="$t('noOperationsFound')"
      @refresh="getOperations"
    >
      <template slot="action-tools">
        <el-button size="small" type="primary" class="el-button_icon--prefix">
          <icon height="14" width="14" name="add-circle" />{{ $t('topUp') }}
        </el-button>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'

/* eslint-disable */
const operations = [
  { id: '2830284002834', operation: '2830284002834', amount: '1,000 ₽', date: 1570020806933, status: 'completed', description: 'Operation description 1' },
  { id: '2239084029880', operation: '2239084029880', amount: '1,000 ₽', date: 1570020806935, status: 'completed', description: 'Operation description 2' },
  { id: '3223423425455', operation: '3223423425455', amount: '16,000 ₽', date: 1570020806943, status: 'pending', description: 'Operation description 3' },
  { id: '4354353453543', operation: '4354353453543', amount: '11,000 ₽', date: 1570020806903, status: 'aborted', description: 'Operation description aborted' },
]
/* eslint-enable */

export default {
  name: 'SettingsWallet',

  components: {
    DataTable,
  },

  data() {
    return {
      operations: [],

      tableColumns: ['operation', 'amount', 'date', 'status', 'description'],

      loading: false,
    }
  },

  async beforeMount() {
    this.loading = true
    await this.getOperations()
    this.loading = false
  },

  methods: {
    async getOperations() {
      operations.forEach(item => {
        if (item.date) {
          item.date = new Date(item.date).toLocaleTimeString(this.$i18n.locale)
        }
      })
      this.operations = operations
    },
  },
}
</script>

<i18n>
{
  "en": {
    "topUp": "Top up",
    "noOperations": "You have no operations yet",
    "noOperationsFound": "No matching operations found"
  },
  "ru": {
    "topUp": "Пополнить",
    "noOperations": "У вас пока нет операций",
    "noOperationsFound": "Операции, соответствующие вашему запросу, не найдены"
  }
}
</i18n>

<style scoped lang="scss">
.settings-wallet {
  width: 100%;
  padding: $spacing-l;
}
.settings-wallet__panel {
  display: flex;
  margin-bottom: $spacing-l;
}
.settings-wallet__card {
  margin-right: $spacing-m;
  span {
    display: block;
    margin-bottom: $spacing-s;
  }
  h6 {
    font-weight: 600;
  }
}
</style>
